<template>
  <div class="container">
    <LayoutViewWrapperNarrow>
      <div class="content">
        <BaseH1 :text="'Hasło zostało zmienione'" :short="true" />
        <SvgIllustrationsTick />
        <TextDescription
          :text="'Nowe hasło zostało ustalone pomyślnie. Zaloguj się, aby korzystać z serwisu'"
        />
        <TextHint :text="redirectMessage" />
      </div>
    </LayoutViewWrapperNarrow>
    <NavMobile v-if="isMobile" :isLoginFirst="true" />
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ROUTE_LOGIN_NAME } from "@/router/constants";
import useIsMobile from "@/composables/useIsMobile";

import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsTick from "@/components/Svg/Illustrations/SvgIllustrationsTick.vue";
import TextHint from "@/components/UI/TextHint.vue";
import NavMobile from "@/components/NavMobile.vue";

const TIME_IN_MS_BEFORE_REDIRECT = 10000;
const ROUTE_LOGIN = {
  name: ROUTE_LOGIN_NAME,
};

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    TextDescription,
    SvgIllustrationsTick,
    TextHint,
    NavMobile,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const router = useRouter();
    const redirectCounter = ref(TIME_IN_MS_BEFORE_REDIRECT);
    const redirectMessage = computed(() => {
      const counter = (redirectCounter.value / 1000).toFixed(0);
      return `Przekierowanie do strony loginu za ${counter}....`;
    });

    const handleDelayedRedirection = async () => {
      const intervalId = setInterval(redirectCountdown, 1000);
      await setRedirectTimer();
      clearInterval(intervalId);
    };

    const redirectCountdown = () => {
      redirectCounter.value -= 1000;
    };

    const setRedirectTimer = async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(redirectToLogin());
        }, TIME_IN_MS_BEFORE_REDIRECT);
      });
    };

    const redirectToLogin = () => {
      router.push(ROUTE_LOGIN);
    };

    onMounted(() => {
      handleDelayedRedirection();
    });

    return {
      isMobile,
      redirectMessage,
    };
  },
};
</script>

<style scoped>
.container {
  min-height: calc(100vh - 2 * var(--header-default--height));
  display: flex;
  align-items: center;
}
.content {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 80px;
}

@media (max-width: 1200px) {
  .container {
    min-height: inherit;
  }
  .content {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 60px;
  }
}
</style>
